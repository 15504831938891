import { E_CategoryIds } from '@shared/constants';

export namespace Constants {
  export const PRIVATE_PAYMENT_PROVIDER_ID = 'PRIVATE';
  export const NEW_PATIENT_EXAM_CATEGORY_ID = E_CategoryIds.NewLapsedDental;
  export const CONSULTATION_CATEGORY_ID = E_CategoryIds.Consultation;
  export const SITE_SELECTION_COOKIE = 'site_selection';
  export const ALL_SITES_ID = 'ALL_SITES';
  export const ALL_SITES_NAME = 'All Sites';

  // BASE ROUTE (TO BE DASHBOARD)
  export const ROUTE_BASE = 'online-booking';

  // MANAGE UPGRADE ROUTE
  export const ROUTE_MANAGE_UPGRADE = '/upgrade';

  // ONLINE BOOKING ROUTES
  export const ROUTE_ONLINE_BOOKING_APPOINTMENTS = '/online-booking/appointments';
  export const ROUTE_ONLINE_BOOKING_PLANS = '/online-booking/plans';
  export const ROUTE_ONLINE_BOOKING_SETTINGS = '/online-booking/settings';

  // NOTIFICATION ROUTES
  export const ROUTE_PATIENT_NOTIFICATION_SETTINGS = '/patient-notifications/settings';
  export const ROUTE_PATIENT_NOTIFICATION_TYPES = '/patient-notifications/types';

  // CUSTOMISE ROUTES
  export const ROUTE_CUSTOMISE_BRANDS = '/customise/brands';
  export const ROUTE_CUSTOMISE_PORTAL = '/customise/portal';
  export const ROUTE_CUSTOMISE_PRACTITIONERS = '/customise/practitioners';
  export const ROUTE_CUSTOMISE_SETTINGS = '/customise/settings';

  // ONLINE SIGNING ROUTES
  export const ROUTE_ONLINE_SIGNING_DOCUMENT_TYPES = '/online-signing/document-types';
  export const ROUTE_ONLINE_SIGNING_SETTINGS = '/online-signing/settings';

  // PAYMENT ROUTES
  export const ROUTE_PAYMENTS_LINK_WITH_PAYMENT_PROVIDER = '/payments/link-with-payment-provider';
  export const ROUTE_PAYMENTS_TYPES = '/payments/types';
  export const ROUTE_PAYMENTS_SETTINGS = '/payments/settings';

  // PATIENT DETAILS ROUTES
  export const ROUTE_PATIENT_DETAILS_DATA = '/patient-details/data';

  // DEVMODE ROUTES
  export const ROUTE_DEV_MODE_BULK_DATA = 'dev-mode/bulk-data';
  export const ROUTE_DEV_MODE_DIAGNOSTICS = 'dev-mode/diagnostics';
  export const ROUTE_DEV_MODE_LINKS = 'dev-mode/links';

  // IMPERSONATION ROUTES
  export const ROUTE_SUPPORT_TOOLS = '/support-tools';
  export const ROUTE_SUPPORT_TOOLS_QUERY_BUILDER = '/support-tools/query-builder';
  export const ROUTE_SUPPORT_TOOLS_CLEAR_API_CACHE = '/support-tools/clear-api-cache';

  // DEVICES ROUTES
  export const ROUTE_DEVICES_PAIR = '/devices/pair';
  export const ROUTE_DEVICES_SETTINGS = '/devices/settings';
}
