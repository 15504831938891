<div *ngIf="commonService.sites.length">
  <div class="relative flex h-16 w-full items-center">
    <ul *ngIf="commonService.enableWinter" class="g-snows">
      <li *ngFor="let _ of [].constructor(100)"></li>
    </ul>
    <a class="cursor-pointer px-4" href="{{ site_url }}">
      <h1>
        <span class="sr-only">{{ commonService.practice.name }}</span>
        <svg-icon *ngIf="!commonService.enableWinter" src="assets/dentally_portal_revised_DP_linear_white.svg" class="w-40" [applyClass]="true"></svg-icon>
        <div *ngIf="commonService.enableWinter" class="relative">
          <span class="absolute -top-[4px] -left-[10px] -rotate-[25deg] -scale-x-100">
            <svg-icon *ngIf="commonService.enableWinter" src="assets/santa_hat.svg" class="w-[28px]" [applyClass]="true"></svg-icon>
          </span>
          <svg-icon *ngIf="commonService.enableWinter" src="assets/dentally_portal_revised_DP_linear_winter.svg" class="w-40" [applyClass]="true"></svg-icon>
        </div>
      </h1>
    </a>
  </div>
  <div class="flex flex-col justify-between bg-gray-900 p-4">
    <nav class="h-full">
      <div class="pb-3 font-semibold uppercase tracking-wider text-gray-300">
        {{ commonService.practice.name }}
      </div>
      <div class="mb-6" *ngIf="commonService.isMultiSite" data-intercom-target="Site selector">
        <dentr-site-switcher></dentr-site-switcher>
      </div>
      <ul>
        <ng-container *ngFor="let item of navItems">
          <li *ngIf="item.show === undefined ? true : item.show">
            <a
              role="link"
              (click)="item.dropdown && toggleDropdown(item.dropdown)"
              [routerLink]="item.route"
              class="mb-1 flex items-center justify-between rounded-md p-2 font-medium"
              [ngClass]="{
                'hover:bg-gray-800': item.dropdown && !dropdowns[item.dropdown]
              }"
            >
              <div class="flex items-center" [attr.data-e2e-nav]="item.e2eTestSelector" [attr.data-unit-test-nav]="item.unitTestSelector">
                <ng-icon [name]="item.icon" size="22" class="mr-3"></ng-icon>
                {{ item.title }}
              </div>
              <ng-icon
                *ngIf="item.dropdown"
                name="heroChevronRight"
                class="transition-all"
                size="18"
                [ngClass]="{ 'rotate-90': item.dropdown && dropdowns[item.dropdown] }"
              ></ng-icon>
            </a>
            <ul
              class="m-0 mb-1 ml-5 p-0"
              [@accordionAnimation]
              *ngIf="item.dropdown && dropdowns[item.dropdown]"
              [ngClass]="{
                'border-l border-gray-700': item.dropdown && dropdowns[item.dropdown]
              }"
            >
              <ng-container *ngFor="let subItem of item.subItems">
                <li
                  *ngIf="subItem.show === undefined ? true : subItem.show"
                  [attr.data-e2e-nav]="subItem.e2eTestSelector"
                  [attr.data-unit-test-nav]="subItem.unitTestSelector"
                  [routerLinkActiveOptions]="{ exact: true }"
                  routerLinkActive
                  [ngClass]="{
                    'bg-grape-700 text-white': activeRoute.includes(subItem.route),
                    'hover:bg-gray-800': !activeRoute.includes(subItem.route)
                  }"
                  role="link"
                  [routerLink]="subItem.route"
                  class="ml-5 mb-1 flex cursor-pointer items-center rounded-md p-2 pl-6 text-sm font-medium"
                  (keydown.enter)="navigateTo(subItem.route)"
                >
                  <a>{{ subItem.title }}</a>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
    </nav>
  </div>
</div>
<div class="px-4">
  <dentr-refresh-cache></dentr-refresh-cache>
</div>
